import React from 'react'
import PropTypes from "prop-types"

function Skills({windowWidth}) {
  return (
    <>
        <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col mb-5"} flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-1"}`}>
              <p className={`text-xl font-light ${windowWidth >= 1200 && "py-5"}`}>Languages</p>
          </div>
          <div className='border-l-[1px] border-black'></div>
          <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
              <div className={`w-full ${windowWidth >= 1200 ? "py-5" : "pt-2"}`}>
                <p>Java, C++, Python, C, Dart, JavaScript, PHP</p>
              </div>
          </div>
        </div>

        <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col mb-5"} flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-1"}`}>
              <p className={`text-xl font-light ${windowWidth >= 1200 && "py-5"}`}>Web & Mobile Technologies</p>
          </div>
          <div className='border-l-[1px] border-black'></div>
          <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
              <div className={`w-full ${windowWidth >= 1200 ? "py-5" : "pt-2"}`}>
                <p>HTML, CSS, REST APIs, Android with Java, Debugging</p>
              </div>
          </div>
        </div>

        <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col mb-5"} flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-1"}`}>
              <p className={`text-xl font-light ${windowWidth >= 1200 && "py-5"}`}>Frameworks</p>
          </div>
          <div className='border-l-[1px] border-black'></div>
          <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
              <div className={`w-full ${windowWidth >= 1200 ? "py-5" : "pt-2"}`}>
                <p>Flutter, Laravel, Tailwind CSS, React, Angular</p>
              </div>
          </div>
        </div>

        <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col mb-5"} flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-1"}`}>
              <p className={`text-xl font-light ${windowWidth >= 1200 && "py-5"}`}>Databases</p>
          </div>
          <div className='border-l-[1px] border-black'></div>
          <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
              <div className={`w-full ${windowWidth >= 1200 ? "py-5" : "pt-2"}`}>
                <p>Firebase, MySQL, MongoDB, SQflite</p>
              </div>
          </div>
        </div>

        <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col mb-5"} flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-1"}`}>
              <p className={`text-xl font-light ${windowWidth >= 1200 && "py-5"}`}>Tools</p>
          </div>
          <div className='border-l-[1px] border-black'></div>
          <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
              <div className={`w-full ${windowWidth >= 1200 ? "py-5" : "pt-2"}`}>
                <p>Git, GitHub, VS Code, Android Studio, Adobe XD, Figma, Inkscape, Photoshop</p>
              </div>
          </div>
        </div> 
    </>
  )
}

Skills.propTypes = {
    windowWidth: PropTypes.number.isRequired,
}

export default Skills