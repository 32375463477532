import { useState, useEffect } from "react";
import { Home, Projects, Resume } from "./screens/_screens"
import { GitHubLogo, LinkedInLogo, XLogo } from "./assets/social-logos/_social_logos";

function App() {
  const [currentPage, setCurrentPage] = useState("home");
  
  const cPage = sessionStorage.getItem("currentPage") === null ? currentPage: sessionStorage.getItem("currentPage");

  // Step 1: Create a state variable to store the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
  // Step 2: Attach an event listener to the window resize event
  useEffect(() => {
    const handleResize = () => {
      // Step 3: Update the state variable on window resize
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="h-full">
      <div className={`flex justify-between pt-5 ${windowWidth >= 500 ? "mx-10" : "mx-5"}`}>
        <ul className="flex">
          <li className={`mr-5 cursor-pointer ${cPage === "home" && "underline"}`}  onClick={() => {setCurrentPage("home"); sessionStorage.setItem("currentPage", "home")}}>Home</li>
        </ul>
        <ul className="flex">
          <li className={`mr-5 cursor-pointer ${cPage === "projects" && "underline"}`} onClick={() => {setCurrentPage("projects"); sessionStorage.setItem("currentPage", "projects")}}>Projects</li>
          <li className={`mr-5 cursor-pointer ${cPage === "resume" && "underline"}`} onClick={() => {setCurrentPage("resume"); sessionStorage.setItem("currentPage", "resume")}}>Resume</li>
        </ul>
      </div>

      {cPage === "home" && <Home windowWidth={windowWidth}/>}
      {cPage === "projects" && <Projects windowWidth={windowWidth}/>}
      {cPage === "resume" && <Resume windowWidth={windowWidth}/>}

      <div className="w-full border-t-[1px] border-black py-5 px-10">
        <div className={`w-full flex flex-wrap ${windowWidth >= 520 ? "justify-between" : "justify-center"} place-items-center font-light gap-y-5 gap-x-10`}>
          <div className={`flex flex-col ${windowWidth < 520 && "place-items-center"}`}>
            <p>Victor Mutuku<span className={`${windowWidth < 950 && "hidden"}`}>. All Rights Reserved. 2024</span></p>
            <p className={`${windowWidth >= 950 && "hidden"}`}>All Rights Reserved. 2024</p>
          </div>

          <div className={`flex flex-col ${windowWidth < 520 && "place-items-center"}`}>
            <a href="mailto:victor.mmuu@gmail.com" className={`${windowWidth >= 950 && "hidden"} mb-2`}>E-mail: victor.mmuu@gmail.com</a>
            <a href="tel:+254700671519">Tel No: +254700671519</a>
          </div>
          <a href="mailto:victor.mmuu@gmail.com" className={`${windowWidth < 950 && "hidden"}`}>E-mail: victor.mmuu@gmail.com</a>

          <div className={`${windowWidth < 637 && windowWidth >= 521  && "w-full place-items-center"} ${windowWidth < 520 && "place-items-center"} flex flex-col `}>
            <p className="mb-2">Socials</p>
            <div className="flex gap-x-2 ">
              
              <a href="https://www.linkedin.com/in/victor-mutuku/" target="_blank" rel="noreferrer">
                <img src={LinkedInLogo} alt="LinkedIn Logo" className="w-[20px] h-[20px]"/>
              </a>
              <a href="https://github.com/victormutuku" target="_blank" rel="noreferrer">
                <img src={GitHubLogo} alt="GitHub Logo" className="w-[20px] h-[20px]"/>
              </a>
              <a href="https://twitter.com/victormutuku_D" target="_blank" rel="noreferrer">
                <img src={XLogo} alt="X Logo" className="w-[20px] h-[20px]"/>
              </a>
            </div>
          </div>
          

        </div>

      
      </div>

    </section>
  );
}

export default App;