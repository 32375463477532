import React from 'react'
import PropTypes from "prop-types"

function Freelance({windowWidth}) {
  return (
    <div className={`w-full h-full flex-col flex`}>
        <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
            <p className='text-xl font-light mb-1'>Freelancing</p>
        </div>
        <div className={`w-full font-light`}>
            <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                <p className='pr-5'>Worldwide (Remote)</p>
                <p>Nov 2023 - Jan 2024 (Full-time) ; Feb 2024 - June 2024 (Part-time)</p>
            </div>
            <div className='w-full pb-10'>
                <p className='mb-1'>
                I freelanced as a web developer and mobile developer for a period of 8 months. For the first 3 months, I was working feelaance full-time. After acquiring my current job, I worked part-time for 5 months to: 1) Finish up on work I had with the client. 2) Provide maintenance for previous work done including updates to their system.
                </p>
                <p className='mb-1'>
                  In this period of time, I was able to achieve the following:
                </p>
                
                <ul className='ml-5 list-disc list-outside'>
                  <li className='mb-2'>Implemented client's web UI designs to create a functional and responsive website and web application enabling easy engagement with customers. This was accomplished using React JS.</li>
                  <li className='mb-2'>Implemented client’s mobile UI designs creating a mobile application with an easy-to-use interface resulting in increased customer reach. This was accomplished using Flutter.</li>
                  <li className='mb-2'>Created high-level UI designs for web and mobile platforms for a new software solution that the client had. This was accomplished using Figma.</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

Freelance.propTypes = {
    windowWidth: PropTypes.number.isRequired,
}

export default Freelance