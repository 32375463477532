import React from 'react'
import PropTypes from "prop-types"

function Farmtrics({windowWidth}) {
  return (
    <div className={`w-full h-full flex-col flex`}>
        <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
            <p className='text-xl font-light mb-1'>Frontend Developer</p>
        </div>
        <div className={`w-full font-light`}>
            <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                <p className='pr-5'><a href="https://www.farmtrics.com/" className='border-b-[1px] border-black'>Farmtrics - Kigali, Rwanda</a> (Remote)</p>
                <p>Feb 2023 - Sep 2023</p>
            </div>
            <div className='w-full pb-10'>
                <p className='mb-1'>
                Worked as a frontend developer for Farmtrics, a data analytics startup based in Kigali, Rwanda. During the 7 months, I was able to achieve the following:
                </p>
                <ul className='ml-5 list-disc list-outside'>
                <li className='mb-2'>Designed and implemented user interfaces for the company's website and web application leading to a 20% increase in website visits.</li>
                <li className='mb-2'>Designed and implemented user interfaces for the company's mobile application enabling the company to meet 50% of its target goals of the first quarter.</li>
                <li className='mb-2'>Implemented search functionality in the company's web application allowing users to look up their projects and other user-generated data.</li>
                <li className='mb-2'>Implemented a dynamic interface that allowed the administrator to easily add and remove language translations.</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

Farmtrics.propTypes = {
    windowWidth: PropTypes.number.isRequired,
}

export default Farmtrics