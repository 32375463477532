import PropTypes from "prop-types";

function Certifications({windowWidth}) {
    return (
        <>
            <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col"} flex`}>
                <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
                    <p className='text-xl font-light'>AWS Certified Solutions Architect - Associate</p>
                </div>
                <div className={`${windowWidth< 1200 && "hidden" } border-l-[1px] border-black`}></div>
                <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
                    <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                        <p className='pr-5'>Issued January 2024</p>
                        <p>Expires January 2027</p>
                    </div>
                    <div className='w-full pb-10'>
                    <p className={`${windowWidth >= 1200 ? "mb-10" : "mb-5"}`}>Skills: Amazon Web Services (AWS), Cloud Architecture, Cloud Infrastructure, Cloud Services</p>
                        
                    <a href="https://www.credly.com/badges/e2100922-a4b3-4c62-be82-d6b301c51674" target='_blank' rel='noreferrer' className='border-[1px] border-black py-2 px-5 rounded-full'>View Credential</a>
                    </div>
                </div>
            </div>
            <div className={`w-full h-full ${windowWidth >= 1200 ? "flex-row justify-between" : "flex-col"} flex`}>
                <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
                    <p className='text-xl font-light'>AWS Certified Cloud Practitioner</p>
                </div>
                <div className={`${windowWidth< 1200 && "hidden" } border-l-[1px] border-black`}></div>
                <div className={`w-full font-light ${windowWidth >= 1200 && "pl-10"}`}>
                    <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                        <p className='pr-5'>Issued October 2023</p>
                        <p>Expires October 2026</p>
                    </div>
                    <div className='w-full pb-10'>
                    <p className={`${windowWidth >= 1200 ? "mb-10" : "mb-5"}`}>Skills: Amazon Web Services (AWS), Cloud Computing</p>
                        
                    <a href="https://www.credly.com/badges/6e449e3a-eade-4314-848c-c3d28324e327" target='_blank' rel='noreferrer' className='border-[1px] border-black py-2 px-5 rounded-full'>View Credential</a>
                    </div>
                </div>
            </div>
        </>
    )

}

Certifications.propTypes = {
    windowWidth : PropTypes.func.isRequired,
}

export default Certifications