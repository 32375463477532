import React from 'react'
import PropTypes from "prop-types"
import { AMT, Freelance, Farmtrics, RedCross, Indra } from "../components/experience/_experience"
import CV from "../assets/documents/CV.pdf"
import ArrowDown from "../assets/icons/arrow-down.svg"
import Education from '../components/Education'
import Skills from '../components/Skills'
import Certifications from '../components/Certifications'

function Resume({windowWidth}) {
  return (
    <section className={`${windowWidth >= 600 ? "px-20" : "px-5"} mt-10 pb-10`}>
        <div className="w-full flex justify-between">
          <p className='text-3xl font-medium mb-10'>Resume</p>
          <a href={CV} download="Victor-Mutuku-CV" target='_blank' rel='noreferrer' className='flex place-items-center h-fit border-[1px] border-black py-2 px-5 rounded-full'>Download <img src={ArrowDown} alt=""  className='w-[15px] h-[15px] ml-1'/></a>
        </div>
        <p className='text-2xl mb-7'>Experience</p>

        <Indra windowWidth={windowWidth}/>

        <Freelance windowWidth={windowWidth}/>

        <Farmtrics windowWidth={windowWidth}/>

        <AMT windowWidth={windowWidth}/>

        <RedCross windowWidth={windowWidth}/>
        
        <p className='text-2xl mt-10 mb-7'>Education</p>
        <Education windowWidth={windowWidth} />
        
        <p className='text-2xl mt-10 mb-7'>Skills</p>
        <Skills windowWidth={windowWidth}/>
        
        <p className='text-2xl mt-10 mb-7'>Certifications</p>
        <Certifications windowWidth={windowWidth}/>

        <div className="w-full flex justify-center place-items-center mt-10">
          <a href={CV} download="Victor-Mutuku-CV" target='_blank' rel='noreferrer' className='flex place-items-center h-fit border-[1px] border-black py-2 px-5 rounded-full'>Download<img src={ArrowDown} alt=""  className='w-[15px] h-[15px] ml-1'/></a>
        </div>  
    </section>
  )
}

Resume.propTypes = {
  windowWidth: PropTypes.number.isRequired,
}

export default Resume