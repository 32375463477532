import React, { useState } from 'react'
import PropTypes from "prop-types"
import VictorImg from  "../assets/images/progressive-MOK_8322.jpg"
import VictorImgSmall from  "../assets/images/progressive-victor-img-small.jpg"

function Home({windowWidth}) {

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div>
      <div className={`mt-16 ${windowWidth >= 1000 ? "flex-row" : "flex-col mb-10"} flex gap-x-5 justify-center place-items-center pb-10 px-5`}>
        {/* Fix image loading time by using srcset such that I should have a low-res, mid-res and high-res image versions of both images */}
          <div className={`${windowWidth>= 1000 ? "h-[500px] overflow-hidden rounded-lg" : "h-[200px] w-[200px] rounded-full overflow-hidden mb-5"}`}>
            { windowWidth >= 1000 ?
              <img src={VictorImg} alt="" className={`h-[500px] bg-contain ${isLoaded ? '' : 'blur-[10px]'}`} onLoad={handleImageLoad}/> :
              <img src={VictorImgSmall} alt="" className={`h-[200px] bg-contain ${isLoaded ? '' : 'blur-[10px]'}`} onLoad={handleImageLoad}/>
            }

          </div>
          <div className={`${windowWidth >= 1000 ? "h-[500px] px-10" : "h-fit px-5"} max-w-[500px] border-[1px] py-5 rounded-lg overflow-hidden`}>
            <p className='text-3xl font-medium mb-10'>Intro</p>
            <p className='mb-2'>Hi, my name is Victor Mutuku, and I am a frontend developer, mobile app developer, and UI/UX designer based in Nairobi, Kenya. Currently, I work as a software engineer at Indra Limited, Kenya. I am open to remote roles in frontend web development, mobile development, and software engineering.</p>
            <p className='mb-5'> I am passionate about solving real-life problems through innovative tech solutions. Beyond tech, I enjoy listening to music, singing, and mobile photography. I look forward to connecting and making a positive impact through code.</p>
          </div>
      </div>
      {/* <div className='h-1 w-full'></div> */}
    </div>
  )
}

Home.propTypes = {
  windowWidth: PropTypes.number.isRequired,
}

export default Home