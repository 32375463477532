import React from 'react'
import PropTypes from "prop-types"


function RedCross({windowWidth}) {
  return (
    <div className={`w-full h-full flex-col flex`}>
        <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
        <p className='text-xl font-light mb-1'>Community Service Intern</p>
        </div>
        <div className={`w-full font-light`}>
            <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                <p className='pr-5'><a href="https://web.facebook.com/p/Kenya-Red-Cross-Nyeri-Branch-100071241051378" className='border-b-[1px] border-black'>Kenya Red Cross Society - Nyeri, Kenya</a> (Onsite)</p>
                <p>Jan 2020 - Mar 2020</p>
            </div>
            <div className='w-full pb-5'>
                <p className='mb-1'>
                Worked as a community service intern at Kenya Red Cross Society (KRCS), Nyeri Branch, Kenya. This was in line with the requirements of the completion of my degree. In the 3 months, I was able to achieve the following:
                </p>
                <ul className='ml-5 list-disc list-outside'>
                <li className='mb-2'>Participated in community development projects such as making of brown envelopes to be used in dissemination drives.</li>
                <li className='mb-2'>Involved in dissemination of information to Kagumo Boys High School.</li>
                <li className='mb-2'>Assisted in a rescue mission involving a collapsed rock quarry.</li>
                <li className='mb-2'>Involved in data entry, report writing, budgeting and in disseminating new members of the team.</li>
                <li className='mb-2'>Participated in helping a community whose houses had succumbed to a fire.</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

RedCross.propTypes = {
  windowWidth: PropTypes.number.isRequired,
}

export default RedCross