import React from 'react'
import PropTypes from "prop-types"

function AMT({windowWidth}) {
  return (
    <div className={`w-full h-full flex-col flex`}>
        <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
        <p className='text-xl font-light mb-1'>UI/UX Intern</p>
        </div>
        <div className={`w-full font-light`}>
            <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                <p className='pr-5'><a href="https://amt.africa/" className='border-b-[1px] border-black'>AMT Technologies - Nairobi, Kenya</a> (Hybrid)</p>
                <p>Feb 2022 - Apr 2022</p>
            </div>
            <div className='w-full pb-10'>
                <p className='mb-1'>
                Worked as a UI/UX designer for my school based internship at AMT Technologies, an automotive tech startup based in Nairobi, Kenya that focuses on fleet management. In the 3 months, I was able to achieve the following:
                </p>
                <ul className='ml-5 list-disc list-outside'>
                <li className='mb-2'>Learnt the basics of UI/UX design using Adobe XD.</li>
                <li className='mb-2'>Created and designed mockups for the company's web and mobile applications enabling developers to focus on implementation of frontend and backend logic.</li>
                <li className='mb-2'>Collaborated with the product managers to come up with a color scheme, app name and logo for the company's mobile application.</li>
                <li className='mb-2'>Worked in a team to do user testing to test on ease of usability of designed interfaces</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

AMT.propTypes = {
  windowWidth: PropTypes.number.isRequired,
}

export default AMT