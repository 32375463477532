import React from 'react'
import PropTypes from "prop-types"

function Education({windowWidth}) {
  return (
    <>
        <div className={`w-full h-full flex-col flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
              <p className='text-xl font-light mb-1'>Bachelor's Degree in Informatics and Computer Science (BICS)</p>
          </div>
          <div className={`w-full font-light`}>
              <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                  <p className='pr-5'>Strathmore Unviersity - Nairobi, Kenya</p>
                  <p>2019 - 2023</p>
              </div>
              <div className='w-full pb-10'>
                  <p>
                  I attended my undergraduate studies at Strathmore University, Nairobi, Kenya, where I attained a bachelor's degree in informatics and computer science.
                  </p>
              </div>
          </div>
        </div>
        <div className={`w-full h-full flex-col flex`}>
          <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
            <p className='text-xl font-light mb-1'>Machine Learning Track - Summer School</p>
          </div>
          <div className={`w-full font-light`}>
              <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                  <p className='pr-5'>Brno Unviersity of Technology - Brno, Czech Republic</p>
                  <p>July 2023 (2.5 weeks)</p>
              </div>
              <div className='w-full pb-10'>
                  <p>
                  I attended Summer School at the Brno University of Technology where I majored in Machine Learning.
                  </p>
              </div>
          </div>
        </div>
    </>
  )
}

Education.propTypes = {
    windowWidth: PropTypes.number.isRequired,
}

export default Education