import React from 'react'
import  PropTypes from "prop-types"
import AvocadoLogo from '../assets/images/avocado-logo.ico'
import SpStoreLogo from '../assets/images/sp-store-logo.png'
import OnottoLogo from '../assets/images/onotto-logo.png'
import PulaPOSLogo from '../assets/images/pula-logo.png'

function Projects({windowWidth}) {
    const projects = [
        {
            name: "pulaPOS",
            description: "A restaurant POS and management system  designed for the African market",
            projectLink: "https://www.pulapos.com",
            img: PulaPOSLogo,
            bgColor:"bg-white",
            tags: ["web","frontend"]
        },
        {
            name: "Onotto",
            description: "A website and mobile application for Onotto, a note taking application, designed by me",
            projectLink: "https://onotto-website.vercel.app/",
            img: OnottoLogo,
            bgColor:"bg-[#FFFFE7C9]",
            tags: ["mobile", "web", "frontend"]
        },
        {
            name: "Sp-Store",
            description: "An implementation of the E-commerce dashboard design designed by",
            designerLink:"https://www.behance.net/Designer-tanbir",
            designer:"Tanbir Ahmed",
            projectLink: "https://sp-store-alpha.vercel.app/",
            img: SpStoreLogo,
            bgColor: "bg-white",
            tags: ["web", "frontend"]
        },
        {
            name: "Avocado",
            description: "An implementation of the Avocado Dashboard designed by",
            designerLink:"https://www.behance.net/hirushachamod",
            designer: "Hirusha Chamod",
            projectLink: "https://avocado-xi.vercel.app/",
            img: AvocadoLogo,
            bgColor: "bg-[#5cad46]",
            tags: ["web", "frontend"]
        },
    ]

    const projectItem = projects.map(project => 
        <div key={project.name} className={`${windowWidth >= 350 && "min-w-[340px] max-w-[340px]"}`}>
            <div className=' overflow-hidden hover:shadow-lg mb-5'>
                    <a href={project.projectLink} target='_blank' rel='noreferrer'>
                <div className={`h-[200px] w-full rounded-t-md overflow-hidden flex place-items-center justify-center ${project.bgColor}`}>
                        <img src={project.img} alt="" className={`w-[60%] h-[60%] object-contain`}/>
                </div>
                    </a>
                <div className='border-b-[1px] border-black/40 rounded-b-md pt-2 pb-5 px-5'>
                    <p className='mb-2 font-medium cursor-default'>{project.name}</p>
                    <p className='text-sm cursor-default'>{project.description} <a href={project.designerLink} target='_blank' rel='noreferrer' className='border-b-[1px] border-black'>{project.designer}</a></p>
                </div>
            </div>
            <div className="flex gap-x-3 overflow-x-auto px-2">
                { project.tags.map(tag => <div className=' border-black border-[1px] bg-black text-white rounded-full py-2 px-5'>
                    <p className='text-xs'>{tag}</p>
                </div>) }
            </div>
        </div>
    )
  return (
    <section className={`${windowWidth >= 500 ? "px-20" : "px-5"} mt-10 h-fit`}>
        <p className='text-3xl font-medium'>Projects</p>
        <div className='max-w-[1110px] mt-10 pb-10 flex flex-wrap gap-9'>
            {projectItem}
        </div>
    </section>
  )
}

Projects.propTypes = {
    windowWidth: PropTypes.number.isRequired,
}

export default Projects