import React from 'react'
import PropTypes from "prop-types"

function Indra({windowWidth}) {
  return (
    <div className={`w-full h-full flex-col flex`}>
        <div className={`w-full ${windowWidth < 1200 && "mb-2"}`}>
        <p className='text-xl font-light mb-1'>Software Engineer I</p>
        </div>
        <div className={`w-full font-light`}>
            <div className={`flex justify-between w-full ${windowWidth >=1200 ? "mb-4" : "mb-2"}`}>
                <p className='pr-5'><a href="https://www.indracompany.com/en" className='border-b-[1px] border-black'>Indra Limited - Nairobi, Kenya</a> (On-site)</p>
                <p>Feb 2024 - Current</p>
            </div>
            <div className='w-full pb-10'>
                <p className='mb-1'>
                I am currently working as a software engineer at Indra Limited, a software company based in Kenya and a branch of Indra Company, based in Spain. My main role is that of a support engineer but with time I have been able to acquire more tasks that require more than skills than that defined for a support engineer.
                In being a support engineer, I have been able to the following:
                </p>

                <ul className='mb-1 ml-5 list-disc list-outside'>
                  <li className='mb-2'>Provided technical support and troubleshooting for client systems, promptly resolving issues to minimize downtime and ensure operational continuity.</li>
                  <li className='mb-2'>Prepared technical documentation, including functional design documents, to guide developers on feature development and ensure successful solution delivery.</li>
                  <li className='mb-2'>Conducted meetings with clients to acquire more information on the issues they were facing.</li>
                  <li className='mb-2'>Conducted analysis using SQL to identify and resolve client issues promptly.</li>
                </ul>

                <p className='mb-1'>
                Outside of being a support engineer, another of my main roles is to conduct UAT and QA testing of developed features. This is to ensure they meet client requirements and maintain high quality standards before deployment. Other than that, I have also been able to do the following as part of my tasks:
                </p>
                <ul className='ml-5 list-disc list-outside'>
                  <li className='mb-2'>Facilitated communication between client and third party for seamless integration of third party APIs.</li>
                  <li className='mb-2'>Conducted testing of third party API using Postman to ensure clear communication before integration with existing client application.</li>
                  <li className='mb-2'>Participated as a team member to ensure successful development of new client application.</li>
                  <li className='mb-2'>Created a clear and concise user guide to ensure easy onboarding of new users.</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

Indra.propTypes = {
  windowWidth: PropTypes.number.isRequired,
}

export default Indra